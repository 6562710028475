









import { Component, Vue } from 'vue-property-decorator';
@Component({
  name: 'dawagnka',
  components: {}
})
export default class extends Vue {
  private channel: any = '';
  private handleGoPage () {
    this.$router.push({
      name: 'Fill',
      query: {
        channel: this.channel
      }
    });
  }
  private mounted () {
    this.channel = this.$route.query.channel || '';
  }
}
